import React, { useState, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import '../stylesheets/pages/home.scss'

import Template from './Template'
import { Slideshow, Divider, Icon, EventsList, Column } from '../components'
import { Button, Link } from '../components/shared'
import { H3, H5, H6, SubsectionHeader, Caption } from '../components/shared/Typography'

import { getRecords } from '../airtable'
import Images from '../images'
import Util from '../Util'

const { redirect, minifyFullDateRange, pageRoutes: routes, toDate } = Util

function Home () {
  const [events, setEvents] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const getEvents = async () => {
      try {
        const records = await getRecords('Events', {
          view: 'Grid view',
          maxRecords: 4,
          filterByFormula: '{Start Date} >= TODAY()'
        })
        const data = records.map(record => {
          const { fields } = record
          return {
            id: record.id,
            startDate: toDate(fields['Start Date']), // TODO: handle time zone change
            endDate: toDate(fields['End Date']),
            link: fields.Link,
            name: fields.Name,
            location: fields.Location
            // Returns id, filename, size, thumbnails, and url
            // Images: json.fields.Images
          }
        })
        setEvents(data)
      } catch (err) {
        console.error(err)
      } finally {
        setLoading(false)
      }
    }
    getEvents()
  }, [])

  if (loading) return <Template><div>Loading</div></Template>

  const upNext = events[0]
  const otherEvents = events.length > 1 ? events.slice(1) : []
  let commMemApp = events.find((event) => {return event.name.includes("Committee Member App")})
  if (commMemApp === undefined) {
    commMemApp = false
  }

  return (
    <Template navBottomDivider={false}>

      {/* Slideshow */}
      <Row><Col><Slideshow /></Col></Row>
      {/* Slideshow Bottom Headline */}
      <Row>
        <Col>
          <div className="slideshow-headline center">
            <span className="salmon-text">S</span>tanford{' '}
            <span className="salmon-text">V</span>ietnamese{' '}
            <span className="salmon-text">S</span>tudent{' '}
            <span className="salmon-text">A</span>ssociation{' '}
          </div>
        </Col>
      </Row>

      <Divider.Dashed />

      <SubsectionHeader>Check Out Cool Things We Do</SubsectionHeader>

      <Row className="vertically-center">
        {
          !upNext
            ? <NoUpcomingEventsMessage />
            : (
              <EventsComingSoon
                upNextEvent={upNext}
                otherEvents={otherEvents}
              />
            )
        }
        {/* SUBSECTION: Up Next (Intern Link Addition) */}
        {/* TODO: Figure out how to add the intern app link here */}
        {/* <div className="section solid yellow border margin-top links">
            <IconBullet img={Images.pencil}>
              <a href="/">Sign Up</a></IconBullet>
            <Divider.Thin />
            <IconBullet img={Images.eventbrite}>
              <a href="/">Eventbrite</a></IconBullet>
          </div> */}
        {/* SUBSECTION: Weekly Meetings */}
        <Column theme="yellow">
          <Caption.Bold className="salmon-text header">Weekly Meetings</Caption.Bold>
          <H3>Mondays, 7-8pm PT</H3>
          <Caption.Semibold>Okada Lounge</Caption.Semibold>
        </Column>
      </Row>

      <SubsectionHeader>Join the SVSA Fam</SubsectionHeader>
      {commMemApp && 
        <center>
          <p><Link.NewTab href={commMemApp.link}>Apply To Be A Committee Member Here!</Link.NewTab></p>
        </center>}
      <H5 className="center">Why join us? Here are just a few reasons:</H5>

      <Row className="vertically-center">
        {/* SUBSECTION: Explore Identity */}
        <Column theme="navy">
          <Icon.Sticker img={Images.sticker.explore} />
          <H6 className="yellow-text">Explore Identity</H6>
          <p>Everyone comes from different backgrounds and experiences.
            Wherever you are in your journey, we hope to provide a warm
            and safe space for you to reflect on your identity.
          </p>
        </Column>
        {/* SUBSECTION: Find Community */}
        <Column theme="navy">
          <Icon.Sticker img={Images.sticker.people} />
          <H6 className="yellow-text">Find Community</H6>
          <p>While we are known to plan many events on campus, the heart
            of SVSA is, and will always be, the people. We cherish all new,
            old, and current members, so catch us at one of our events or
            even our weekly meetings!
          </p>
        </Column>
        {/* SUBSECTION: Spread Cultural Awareness */}
        <Column theme="navy">
          <Icon.Sticker img={Images.sticker.globe} />
          <H6 className="yellow-text">Spread Cultural Awareness</H6>
          <p>We enjoy celebrating our heritage from big show productions
            like Culture Night to chill pho night outings. Check out our{' '}
          <a href={routes.events} className="yellow-text">Events</a> page for more info!
          </p>
        </Column>
      </Row>

      <H5 className="center">Want to get involved? Come join us at meetings!</H5>
      <div className="announcement center">Mondays, 7-8PM, Okada Lounge</div>
    </Template>
  )
}

export default Home

const NoUpcomingEventsMessage = () => (
  <Column theme="yellow" size="8">
    <p>
      😅 Whoops, we usually list all the cool events here. Don&apos;t worry
      we&apos;re working on it! Please check back soon.
    </p>
  </Column>
)

const EventsComingSoon = ({ upNextEvent, otherEvents }) => (
  <React.Fragment>
    {/* SUBSECTION: Up Next */}
    <Column theme="salmon">
      <Caption.Bold className="yellow-text header">Up Next</Caption.Bold>
      <H3 className="paper-white-text">{upNextEvent.name}</H3>
      <Caption.Semibold>
        {minifyFullDateRange(upNextEvent.startDate, upNextEvent.endDate) + ' PT'}<br />
        {upNextEvent.location}
      </Caption.Semibold>
    </Column>
    {/* SUBSECTION: Other Events */}
    <Column hasTrailer={otherEvents.length} theme="yellow">
      <Caption.Bold className="salmon-text header">Other Events</Caption.Bold>
      <EventsList events={otherEvents} />
      {
        otherEvents.length
          ? (
            <Button.Trailer action={() => redirect('/events')}>
            See More
            </Button.Trailer>
          )
          : null
      }
    </Column>
  </React.Fragment>
)