import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import { Navbar, Divider, Footer } from '../components'

// General template for each page, including the navbar and footer
function Template ({
  children,
  navBottomDivider: showNavBottomDivider = true
}) {
  return (
    <Container className="main">
      <Row><Col><Navbar /></Col></Row>
      <Divider.Dashed visible={showNavBottomDivider} />
      {children}
      <Divider.Dashed />
      <Footer />
    </Container>
  )
}

export default Template
