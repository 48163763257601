/**
 * DateUtil.js
 * ---------------------------------------------
 * Helper functions to handle translating Date
 * objects into readabletext format.
 *
 */

import moment from 'moment'

const DateUtil = {

  toDate: date => date && new Date(date),

  //
  // PARSING
  //

  parseDate: date => {
    const month = date.toLocaleString('en-US', { month: 'short' })
    const day = date.getDate()
    const year = date.getFullYear()
    return { month, day, year }
  },

  parseTime: date => {
    const fulltime = date.toLocaleTimeString('en-US', { timeStyle: 'short' })
    const [hrMin, period] = fulltime.split(' ') // '5:00 PM' => ['5:00', 'PM']
    const [hr, min] = hrMin.split(':') // '5:00' => ['5', '00']
    return { hr, min, period, fulltime }
  },

  parseDateTime: date => {
    const [minifiedDate, minifiedTime] = DateUtil.minifyDateTime(date)
    return {
      date: minifiedDate,
      time: minifiedTime,
      dateTime: DateUtil.dateTimeFormat(minifiedDate, minifiedTime),
      ...DateUtil.parseDate(date),
      ...DateUtil.parseTime(date)
    }
  },

  //
  // FORMATTING
  //

  dateRangeFormat: (start, end) => [start, end].join(' - '),
  dateTimeFormat: (date, time) => [date, time].join(', '),

  //
  // MINIFYING
  //

  // Shortens date to month abbreviation and day (e.g. Feb 4)
  minifyDate: date => {
    const { month, day } = DateUtil.parseDate(date)
    return `${month} ${day}`
  },

  // Shortens date to month abbreviation and day (e.g. Feb 4 2017)
  minifyFullDate: date => {
    const { month, day, year } = DateUtil.parseDate(date)
    return `${month} ${day} ${year}`
  },

  // Shortens time abbreviation (e.g. 9:00 AM => 9 AM)
  minifyTime: (date, { withPeriod = true } = {}) => {
    const { hr, min, period } = DateUtil.parseTime(date)
    const minifiedHourAndMin = +min === 0 ? hr : `${hr}:${min}`
    return withPeriod ? `${minifiedHourAndMin} ${period}` : minifiedHourAndMin
  },

  // Shortens both the date and time into an array
  minifyDateTime: date => {
    return [DateUtil.minifyDate(date), DateUtil.minifyTime(date)]
  },

  // Shortens the date range, not including the year (e.g. Feb 4 - 6)
  minifyDateRange: (start, end) => {
    const startDateObj = DateUtil.parseDate(start)
    const endDateObj = DateUtil.parseDate(end)

    if (DateUtil.areOnSameDate(start, end)) {
      // Truncate date range to a single date, eg. Feb 4
      return DateUtil.minifyDate(start)
    } else if (startDateObj.month === endDateObj.month) {
      // Shorten date range if months are the same, eg. Feb 4 - 6
      const dayRange = DateUtil.dateRangeFormat(
        startDateObj.day,
        endDateObj.day
      )
      return `${startDateObj.month} ${dayRange}`
    } else {
      // No shortening of date range, eg. Feb 28 - Mar 3
      return DateUtil.dateRangeFormat(
        DateUtil.minifyDate(start),
        DateUtil.minifyDate(end)
      )
    }
  },

  // Returns full date range, including year (e.g. Feb 29 - 30 2017)
  minifyFullDateRange: (start, end) => {
    const { minifyDateRange, minifyFullDate, dateRangeFormat } = DateUtil

    if (!start) {
      return 'TBD'
    } else if (start.getFullYear() === end.getFullYear()) {
      // eg. Feb 4 2017
      // eg. Feb 4 - 6 2017
      // eg. Feb 28 - Mar 3 2017
      return `${minifyDateRange(start, end)} ${start.getFullYear()}`
    } else {
      // eg. Dec 29 2017 - Jan 1 2018
      return dateRangeFormat(minifyFullDate(start), minifyFullDate(end))
    }
  },

  minifyTimeRange: (start, end) => {
    const { parseTime, minifyTime, dateRangeFormat } = DateUtil
    const startTime = parseTime(start)
    const endTime = parseTime(end)

    if (DateUtil.areOnSameDate(start, end)) {
      if (startTime.fulltime === endTime.fulltime) {
        // eg. 10 AM
        return minifyTime(start)
      } else if (startTime.period === endTime.period) {
        // eg. 10 - 11 AM
        return dateRangeFormat(
          minifyTime(start, { withPeriod: false }),
          minifyTime(end)
        )
      }
    }

    // eg. 11 AM - 1 PM
    return dateRangeFormat(minifyTime(start), minifyTime(end))
  },

  // Returns full date time range (e.g. Feb 29, 6 - 7 PM)
  minifyFullDatetimeRange: (start, end = null) => {
    if (!start) return 'TBD'

    const { parseDateTime } = DateUtil
    const startDateObj = parseDateTime(start)

    // No end date, so we just return the formatted start date
    if (!end) return startDateObj.dateTime

    const { dateRangeFormat, dateTimeFormat } = DateUtil
    const endDateObj = parseDateTime(end)

    // Both dates are on the same day
    if (startDateObj.date === endDateObj.date) {
      let startTime = startDateObj.time
      if (startDateObj.period === endDateObj.period) {
        // If both times are in the same period (AM or PM),
        // cut off the time period of the start time
        startTime = startTime.split(' ')[0]
      }
      const timeRange = dateRangeFormat(startTime, endDateObj.time)
      return dateTimeFormat(startDateObj.date, timeRange)
    }

    return dateRangeFormat(startDateObj.dateTime, endDateObj.dateTime)
  },

  //
  // OPERATIONS & COMPARISONS
  //

  // Determine the number of days between two dates
  numDaysBetween (start, end) {
    const startMoment = moment(start)
    const endMoment = moment(end)
    return endMoment.diff(startMoment, 'days')
  },

  // Determines whether date objects fall on the same day
  areOnSameDate (start, end) {
    const startDateString = DateUtil.toDate(start).toDateString()
    const endDateString = DateUtil.toDate(end).toDateString()
    return startDateString === endDateString
  }
}

export default DateUtil
