import React from 'react'
import logo from '../images/logo.png'
import '../stylesheets/components/logo.scss'

export default function Logo () {
  const alt = 'SVSA logo (SVSA letters in coral red with a off-white outline, and light yellow shadow)'
  return (
    <div className="logo">
      <img src={logo} alt={alt} className="logo" />
    </div>
  )
}
