import React from 'react'

import Util from '../Util'
const { trimClasses } = Util

// TODO: Create custom icons in addition to font awesome
function Icon ({ img: { src, alt }, className }) {
  const classes = trimClasses('icon', className)
  return <img src={src} alt={alt} className={classes} />
}

const Icons = {
  Sticker: ({ img }) => <Icon img={img} className="lg margin-bottom" />,
  Bullet: ({ img }) => <Icon img={img} className="margin-right" />,
  Custom: Icon
}

Icons.Sticker.displayName = "StickerIcon"
Icons.Bullet.displayName = "BulletIcon"

export default Icons
