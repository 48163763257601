import React from 'react'

// Dependencies
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.scss'

import { HashRouter as Router, Switch, Route } from 'react-router-dom'
import { Home, About, Committee, Events, Event } from './pages'

// TODO: Change favicon
// TODO: Get rid of browser errors
// TODO: Consider using links from SVSA afs instead of uploading images
// TODO: Fix padding of navbar to be more inset than main page content
// TODO: Fix all undefined classes
// TODO: Include airtable credentials using environment variables
// TODO: Install eslint and/or prettier?
// TODO: How to include scoped styles?

function App () {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/about" component={About} />
        <Route exact path="/committee/:name" component={Committee} />
        <Route exact path="/events" component={Events} />
        <Route exact path="/events/:name" component={Event} />
        {/* TODO: Make better 404 not found page */}
        <Route component={() => (<div>404 Not found </div>)} />
      </Switch>
    </Router>
  )
}

export default App
