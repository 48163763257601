import React, { Fragment, useState, useEffect } from 'react'
import { Row } from 'react-bootstrap'
import '../stylesheets/pages/events.scss'

import Template from './Template'
import { Column } from '../components'
import Calendar from '../components/Calendar'
import { Image, Link } from '../components/shared'
import { H1, H3, H5, SubsectionHeader, Tag} from '../components/shared/Typography'
import Images from '../images'

import { faLink } from '@fortawesome/free-solid-svg-icons'

import { getRecords } from '../airtable'
import Util from '../Util'
const {
  toDate,
  minifyFullDateRange,
  minifyTimeRange,
  numDaysBetween,
  createRowsFromElements
} = Util


function Events () {

  const [events, setEvents] = useState([])
  const [loading, setLoading] = useState(true)
  let years = new Set()

  useEffect(() => {
    const getEvents = async () => {
      try {
        const records = await getRecords('Events', {
          view: 'Grid view',
          filterByFormula: `{Quarter}="Archive"`
        })
        const data = records.map(record => {
          const {fields} = record
          return {
            id: record.id,
            name: fields.Name,
            committee: fields['Committee Name'],
            startDate: toDate(fields['Start Date']),  // TODO: handle time zone change
            endDate: toDate(fields['End Date']),
            location: fields.Location,
            link: fields.Link ? fields.Link : null,
            linkText: fields['Link Text'] ? fields['Link Text'] : null,
            formattedName: fields['Formatted Name'],
            image: fields.Images
              ? fields.Images[0].thumbnails.large.url
              : Images.dummy.src
          }
        })
        setEvents(data)
      } catch (err) {
        console.error(err)
      } finally {
        setLoading(false)
      }
    }
    getEvents()
  }, [])
  console.log(events)

  events.map(event => years.add(event.startDate.getFullYear()))
  years = Array.from(years).sort().reverse()
  console.log(years)

  if (loading) return <Template><div>Loading</div></Template>

  return (
    <Template>
      <div className='center'>
        <H1>Things We Do</H1>
        <p>
          Throughout the year, SVSA is proud to host a variety of events
          meant to build community, spread cultural awareness, and bring joy
          to the all who attend.
        </p>
        <p>
          As Stanford runs on the quarter system, we organize events by each
          quarter: Fall, Winter, and Spring. Unfortunately, we are unable to 
          host events during the Summer as our members are enjoying their summer 
          break vacation, but feel free to reach out to us and hit us up when 
          Fall begins again!
        </p>

        {/* 
         <Button.Toggle 
          option1='LIST VIEW' 
          option2='CALENDAR VIEW' 
          content1='LIST CONTENT' 
          content2={<Calendar />} />
        */}

        <Calendar />
        
        <H3>Past Events</H3>
        <p>Throughout the years, we’ve had events with speakers, performers, artists,
          alumni, and even outside communities! While many of these are hard to repeat
          year-to-year, we save them here as reminders of the incredible people we 
          have been able to meet.
        </p>
        <p>If you are interested in hosting an event with us, please don’t hesitate to
          contact our wonderful Co-Chairs:
        </p>
        <span>Tracy Ha, Co-Chair, <Link.Contact email='tracyha@stanford.edu' /></span> 
        <p>Tracy Ly, Co-Chair, <Link.Contact email='tracyly@stanford.edu' /></p>

        <EventsListByYear events={events} years={years} />
      </div>
    </Template>
  )
}

export default Events

const EventsListByYear = ({ events, years }) => {
  return years.map(year => <EventsByYear events={events} year={year} key={year} />)
}

const EventsByYear = ({ events, year }) => {
  const filtered = events.filter(event => {
    return event.startDate.getFullYear() === year;
  })
  filtered.sort((a, b) => b.startDate - a.startDate) 

  return (
    <Fragment>
      <SubsectionHeader>{year}</SubsectionHeader>
      <EventsList events={filtered} />
    </Fragment>
  )
}

const EventsList = ({ events }) => { 
  return createRowsFromElements(
    events.map(event => <Event event={event} key={event.id} />))
}

const Event = ({ event }) => {
  const startDate = toDate(event.startDate)
  const endDate = toDate(event.endDate)
  const date = minifyFullDateRange(startDate, endDate)
  const time = minifyTimeRange(startDate, endDate)
  const numDaysBetweenDates = numDaysBetween(startDate, endDate)

  return (
    <Column theme="yellow" contentClasses="extra-padding">
      <div>
        <Tag.Dark className="salmon event-tag committee-label">{event.committee}</Tag.Dark>
        <Image.Event src={event.image} eventName={event.name} />
      </div>
      <div className="event-intro">
        <Tag.Dark className="navy event-tag">{date}</Tag.Dark>
        <H5><a href={'#/events/' + event.formattedName}>{event.name}</a></H5>
      </div>
      <div className="timeplace">
        <p className="time">
          {time}
          {numDaysBetweenDates > 0 && <sup>+{numDaysBetweenDates}</sup>}
        </p>
        <p className="place">{event.location}</p>
      </div>
      <p>{event.text}</p>
      {event.link != null &&
        <Link.Icon
          href={event.link}
          type="event"
          icon={faLink}
          linkClasses="event-link"
          iconClasses="event-icon"
          text={event.linkText}
        />
      }
    </Column>
  )
}