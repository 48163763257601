import React, { useState, useEffect } from 'react'
import '../stylesheets/components/navbar.scss'

import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'

import { Logo } from '../components'
import Util from '../Util'

import { getRecords } from '../airtable'

const { pageRoutes: routes } = Util

function RNavbar () {
  // TODO: Figure out how to highlight nav link for current page

  const [committees, setCommittees] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const getCommittees = async () => {
      try {
        const records = await getRecords('Committees', {
          view: 'Grid view'
        })
        const data = records
          .map(record => {
            const { fields } = record
            return {
              name: fields.Name,
              route: fields.Route
            }
          })
          .filter(record => record.name && record.route)
        setCommittees(data)
      } catch (err) {
        console.error(err)
      } finally {
        setLoading(false)
      }
    }
    getCommittees()
  }, [])

  return (
    <Navbar expand="lg">
      <Navbar.Brand href={routes.home}>
        {/* TODO: Make logo change color on a different page */}
        <Logo />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" className="navbar-toggle" />
      <Navbar.Collapse className="nav-items-container">
        <Nav className="nav-items">
          <Nav.Link href={routes.about}>About</Nav.Link>
          <NavItemDivider />
          <NavDropdown title="Committees" className="nav-dropdown">
            {
              loading
                ? (
                  <p>Loading</p>
                )
                : (
                  committees.map(committee => (
                    <NavCommitteeLink key={committee.route} route={committee.route}>
                      {committee.name}
                    </NavCommitteeLink>
                  ))
                )
            }
          </NavDropdown>
          <NavItemDivider />
          <Nav.Link href={routes.events}>Events</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

const NavItemDivider = () => <span className="nav-item-divider">/</span>

const NavCommitteeLink = ({ route, children }) => {
  return (
    <NavDropdown.Item href={routes.committee(route)}>
      {children}
    </NavDropdown.Item>
  )
}

export default RNavbar
