import React from 'react'
import { useState, useEffect } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { Row } from 'react-bootstrap'
import '../stylesheets/pages/event.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock, faMap } from '@fortawesome/free-regular-svg-icons'
import { faAngleLeft, faLink } from '@fortawesome/free-solid-svg-icons'
import { Slide } from 'react-slideshow-image'

import Template from './Template'
import { Column } from '../components'
import { Image, Link } from '../components/shared'
import { H1, Tag, SubsectionHeader, Caption } from '../components/shared/Typography'
import Util from '../Util'
import Images from '../images'

import { getRecords } from '../airtable'

const { toDate, minifyDate, dateRangeFormat, parseTime } = Util

function Event() {

  // Get event name from url 
  const { params: { name } } = useRouteMatch("/events/:name")

  const [event, setEvent] = useState({})
  const [recurringEvents, setEvents] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const formattedName = name.toLowerCase().replace(/\s/g, "-")

    const getEvent = async () => {
      try {
        const record = (await getRecords('Events', {
          view: 'Grid view',
          maxRecords: 1,
          filterByFormula: `{Formatted Name}="${formattedName}"`
        }))[0]
        const { fields } = record
        const eventData = {
          id: record.id,
          name: fields.Name,
          startDate: new Date(fields["Start Date"]),
          endDate: new Date(fields["End Date"]),
          quarter: fields.Quarter,
          recurring_event_type: fields["Recurring Event Type"],
          committee: fields.Committee,
          committeeName: fields["Committee Name"],
          location: fields.Location,
          link: fields.Link,
          linkText: fields['Link Text'] ? fields['Link Text'] : null,
          description: fields.Description,
          images: fields.Images
            ? fields.Images.map(image => image.url)
            : [Images.dummy.src],
          imageDescription: fields['Image Description'] ? fields['Image Description'] : null
        }
        const recurringRecords = (await getRecords('Events', {
          view: 'Grid view',
          filterByFormula: `{Recurring Event Type}="${eventData.recurring_event_type}"`
        }))
        const recurringData = recurringRecords.map(record => {
          const { fields } = record
          return {
            id: record.id,
            name: fields.Name,
            committee: fields['Committee Name'],
            startDate: toDate(fields['Start Date']),  // TODO: handle time zone change
            endDate: toDate(fields['End Date']),
            location: fields.Location,
            link: fields.Link,
            linkText: fields['Link Text'] ? fields['Link Text'] : null,
            image: fields.Images
              ? fields.Images[0].thumbnails.large.url
              : Images.dummy.src
          }
        })
        setEvent(eventData)
        setEvents(recurringData)
      } catch (err) {
        console.error(err)
      } finally {
        setLoading(false)
      }
    }
    getEvent()
  }, [name])

  if (loading) return <Template><div className="center">Loading...</div></Template>

  if (recurringEvents.length == 0) {
    return (
      <Template>
        <div className="center">

          <a href="/#/events">
            <FontAwesomeIcon icon={faAngleLeft} className="small-icon" />
            Back to Events
          </a>
  
          <H1 className="event-name">{event.name}</H1>
  
          <Tag.Light className="salmon">{event.committeeName}</Tag.Light>
  
          <div className="slide-container middle-section">
            <Slide>
              {
                event.images.map((image, idx) => (
                  <SlideImage key={`slider-image-${idx}`} image={image} />
                ))
              }
            </Slide>
            <p className="slideshow-caption">{event.imageDescription}</p>
          </div>
  
          <p>{event.description}</p>
  
          <Row className="middle-section">
            <Column>
              <Tag.Dark className="navy">{minifyDate(event.startDate)}</Tag.Dark>
            </Column>
            <Column>
              <FontAwesomeIcon icon={faClock} className="small-icon" />
              {dateRangeFormat(parseTime(event.startDate)["fulltime"], parseTime(event.endDate)["fulltime"])}
            </Column>
            <Column>
              <FontAwesomeIcon icon={faMap} className="small-icon" />
              {event.location}
            </Column>
          </Row>
  
          <Link.NewTab href={event.link}>
            <FontAwesomeIcon icon={faLink} className="small-icon" />
            Event Link
          </Link.NewTab>
          
        </div>
      </Template>
    )
  }
  else {
    return (
      <Template>
        <div className="center">
  
          <a href="/#/events">
            <FontAwesomeIcon icon={faAngleLeft} className="small-icon" />
            Back to Events
          </a>
  
          <H1 className="event-name">{event.name}</H1>
  
          <Tag.Light className="salmon">{event.committeeName}</Tag.Light>
  
          <div className="slide-container middle-section">
            <Slide>
              {
                event.images.map((image, idx) => (
                  <SlideImage key={`slider-image-${idx}`} image={image} />
                ))
              }
            </Slide>
            <p className="slideshow-caption">{event.imageDescription}</p>
          </div>
  
          <p>{event.description}</p>
          
          <div className="detail-column">
            <Tag.Dark className="navy">{minifyDate(event.startDate)}</Tag.Dark>
          </div>
          <div className="detail-column">
            <FontAwesomeIcon icon={faClock} className="small-icon" />
            {dateRangeFormat(parseTime(event.startDate)["fulltime"], parseTime(event.endDate)["fulltime"])}
          </div>
          <div className="detail-column">
            <FontAwesomeIcon icon={faMap} className="small-icon" />
            {event.location}
          </div>
    
          <Link.NewTab href={event.link} className="event-link">
            <FontAwesomeIcon icon={faLink} className="small-icon" />
            Event Link
          </Link.NewTab>
          
          <SubsectionHeader>Throughout The Years</SubsectionHeader>
          <Row>
            <Events events={recurringEvents} />
          </Row>
        </div>
      </Template>
    )
  }
}

export default Event

const SlideImage = ({ image }) => (
  <div className="each-slide">
    <div style={{ 'backgroundImage': `url(${image})` }}></div>
  </div>
);

const Events = ({ events }) => events.map(event => 
  (
    <EventDisplay 
      event={event}
      key={event.id}
    />
  )
)

const EventDisplay = ({ event }) => (
  <Column>
    <Tag.Dark className="navy recurring-event-tag">{event.startDate.getFullYear()}</Tag.Dark>
    <Image.PastEvent src={event.image} alt={`image of ${event.name}`} />
    <Caption.Bold className="past-event-name">{event.name}</Caption.Bold>
    {event.link != null && 
      <Link.Icon 
        href={event.link} 
        type="event" 
        icon={faLink} 
        linkClasses="event-link" 
        iconClasses="event-icon"
        text={event.linkText}
      />
    }
  </Column>
)