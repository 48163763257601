import React from 'react'
import '../stylesheets/components/divider.scss'

function DashedDivider ({ visible = true }) {
  if (!visible) return null
  return <div className="dashed-divider"></div>
}

function ThinDivider () {
  return <div className="thin-divider"></div>
}

const Dividers = {
  Dashed: DashedDivider,
  Thin: ThinDivider
}

export default Dividers
