import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Row } from 'react-bootstrap'

import DateUtil from './DateUtil'

const PREFIX = '/#'

const route = path => `${PREFIX}${path}`
const Util = {
  route,
  pageRoutes: {
    home: route('/'),
    about: route('/about'),
    committee: name => route(`/committee/${name}`),
    events: route('/events')
  },

  structureRoute: text => text.split(' ').join('-').toLowerCase(),
  destructureRoute: text => Util.capitalize(text, '-'),

  // Gets current url (eg. 'http://localhost:3000/svsa#/about')
  // and grabs everything past the '#' (eg. '/about')
  getCurRoute: () => window.location.href.split('#')[1],

  redirect: route => {
    const base = window.location.href.split('#')[0]
    window.location.href = `${base}#${route}`
  },

  // Render markdown text into markdown html
  markdown: {
    paragraphs: (text, keyPrefix) => {
      const key = idx => `${keyPrefix}-${idx}`
      const paragraphs = (text || '').split('\n')
      return paragraphs.map(
        (p, idx) => {
          if (p === '' && idx < paragraphs.length - 1) {
            return <div key={key(idx)} className="extraSpace"></div>
          }
          return (
            <ReactMarkdown key={key(idx)}>
              { p }
            </ReactMarkdown>
          )
        }
      )
    }
  },

  trimClasses: (...args) => args.filter(arg => arg).join(' '),

  parseChildren: children => {
    return Util.isIterable(children) ? [...children] : children
  },

  // Check if an object is iterable
  // [Source] https://stackoverflow.com/questions/18884249/checking-whether-something-is-iterable
  isIterable: obj => Symbol.iterator in Object(obj),

  // Capitalize the first letter of each word
  // [Source] https://flaviocopes.com/how-to-uppercase-first-letter-javascript/
  capitalize: (text, delimiter = ' ') => {
    if (typeof text !== 'string') return ''
    const words = text.split(delimiter)
    const capitalized = words.map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1)
    })
    return capitalized.join(' ')
  },

  createRowsFromElements: elements => {
    const grouped = Util.groupByThree(elements)
    return grouped.map(( group, idx ) => <Row className='mb-4' key={idx}>{group}</Row>)
  },

  groupByThree: ([a, b, c, ...rest]) => {
    if (rest.length === 0) {
      return [[a,b,c].filter(x => x !== undefined)]
    }
    return [[a,b,c]].concat(Util.groupByThree(rest))
  },

  ...DateUtil
}

export default Util
