import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Util from '../../Util'
const { trimClasses } = Util

const ContactLink = ({ email }) => (
  <NewTabLink href={`mailto:${email}`}>{email}</NewTabLink>
)

const NewTabLink = ({ href, children, ...props }) => (
  <a href={href} target="_blank" rel="noopener noreferrer" {...props}>
    {children}
  </a>
)

const IconLink = ({ href, type, icon, linkClasses, iconClasses, text, ...props }) => {
  if (type === 'event' && text === null) {
    text = 'Event Link'
  }
  return (
    <a className={trimClasses(linkClasses)} href={href} {...props}>
      <FontAwesomeIcon
        icon={icon}
        className={trimClasses('icon', iconClasses)}
      />
      {text}
    </a>
  )
}

const Links = {
  Contact: ContactLink,
  NewTab: NewTabLink,
  Icon: IconLink
}

export default Links
