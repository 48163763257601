import React from 'react'
import { Col } from 'react-bootstrap'
import '../stylesheets/components/column.scss'

import Util from '../Util'

const { trimClasses, parseChildren } = Util

// TODO: rethink props

// Creates a column with a specified size. If not specified, a third
// of the space is taken up by default.
const Column = ({ size = 4, md, theme, hasTrailer, contentClasses, className, children, ...props }) => {

  const classes = trimClasses('section', className)
  const content = parseChildren(children)

  // Grabs the last component if a trailer does exist
  const trailer = hasTrailer ? content.pop() : null

  return (
    <Col
      lg={size}
      md={md || null}
      className={classes}
      {...props}
    >
      <ColContent theme={theme} hasTrailer={hasTrailer} contentClasses={contentClasses}>
        {content}
      </ColContent>
      {trailer}
    </Col>
  )
}

const ColContent = ({ theme, hasTrailer, contentClasses, children }) => {
  // Add class to remove the bottom radius if a trailer exists
  const trailerClass = hasTrailer ? 'zero-bottom-radius' : ''
  return (
    <div className={`section content ${contentClasses} ${theme || ''} ${trailerClass}`}>
      {children}
    </div>
  )
}

export default Column
