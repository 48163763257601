import React from 'react'

export function Calendar() {
  return(
    <iframe
      src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;ctz=America%2FLos_Angeles&amp;src=c3ZzYXB1YmxpY3JlbGF0aW9uc0BnbWFpbC5jb20&amp;color=%23039BE5"
      width="100%"
      height="500"
      frameBorder="0"
      scrolling="no"
      title="svsa-calendar"
      className="calendar"
    />
  );
}

export default Calendar