import React from 'react'
import Util from '../Util'
import '../stylesheets/components/eventsList.scss'

import { Caption } from '../components/shared/Typography'

function EventsList ({ events, type: eventType }) {
  if (!events.length) {
    // TODO: Perhaps create a fancier message
    return <p>No other events listed yet. Please check back later.</p>
  }

  const displayDate = date => {
    return date ? Util.minifyDate(date) : 'TBD'
  }

  return events.map((event, idx) => (
    <div key={`event-${idx}`} className={`event-item ${eventType}-event`}>
      <div className="date caption blackbold">{displayDate(event.startDate)}</div>
      <div className="event-title">
        {event.committee && <Caption.Bold className="committee-subline">{event.committee}</Caption.Bold>}
        {
          event.link
            ? <a href={event.link} className="semibold">{event.name}</a>
            : <div>{event.name}</div>
        }
      </div>
    </div>
  ))
}

export default EventsList
