import React, { useState, useEffect } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { Row } from 'react-bootstrap'
import '../stylesheets/pages/committee.scss'

import Template from './Template'
import { Column } from '../components'
import { Image } from '../components/shared'
import { H1, H3, Subheader, Caption, Tag } from '../components/shared/Typography'
import Util from '../Util'

import { getRecords, findRecord } from '../airtable'

const { markdown: Markdown } = Util

// TODO: Add sidebar

function Committee () {
  // Get committee name from url
  const { params: { name } } = useRouteMatch('/committee/:name')

  const [bios, setBios] = useState({})
  const [page, setPage] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const getBios = async ids => {
      const getBio = async id => {
        const { fields } = await findRecord('Bios', id)
        return {
          id,
          name: fields.Name,
          class: fields.Class,
          pronouns: fields.Pronouns,
          position: fields.Position,
          bio: fields.Bio,
          picture: fields.Picture
            ? fields.Picture[0].thumbnails.large.url
            : null
        }
      }
      const promise = ids.map(async id => await getBio(id))
      return await Promise.all(promise)
    }
    const getCommittees = async () => {
      const record = (await getRecords('Committees', {
        view: 'Grid view',
        maxRecords: 1,
        filterByFormula: `{Route}="${name}"`
      }))[0]
      const bios = await getBios(record.fields.Members)
      const data = {
        id: record.id,
        title: record.fields.Name,
        description: record.fields.Description, // TODO: handle time zone change
        subline: record.fields.Subline
        // Returns id, filename, size, thumbnails, and url
        // Images: json.fields.Images
      }
      setBios(bios)
      console.log(bios)
      setPage(data)
      console.log(data)
      setLoading(false)
    }
    getCommittees()
  }, [name])

  // TODO: Create a loading page
  if (loading) return <Template><div>Loading...</div></Template>

  return (
    <Template>
      <H1 className="has-trailer">{page.title}</H1>
      <Caption.Bold className="subline">{page.subline}</Caption.Bold>
      <Paragraphs keyPrefix="description">{page.description}</Paragraphs>
      <Subheader>Core Members</Subheader>
      <Bios bios={bios} mlh={name === 'mua-lac-hong'} />
    </Template>
  )
}

export default Committee

const Paragraphs = ({ keyPrefix, children }) => {
  return Markdown.paragraphs(children, keyPrefix)
}

const Bios = ({ bios, mlh = false }) => bios.map(
  bio => <Bio key={bio.id} bio={bio} mlh={mlh} />
)

// TODO: Handle intern styling
// TODO: Add little left arrow
// TODO: Handle responsive design
const Bio = ({ bio, mlh }) => (
  <Row>
    <Column size={2} md={3}>
      <Image.Profile src={bio.picture} alt={`image of ${bio.name}`} />
    </Column>
    <Column size={10} md={9} className="bio-container">
      <div className="header">
        <div>
          <H3>{bio.name}</H3>
          <div>
            <Tag.Light className="navy">{bio.class}</Tag.Light>
            {bio.pronouns &&
              <Tag.Light className="navy">{bio.pronouns}</Tag.Light>}
          </div>
        </div>
        <Tag.Dark className="navy lg">{bio.position}</Tag.Dark>
      </div>
      <div className="bio-text">
        {
          mlh
            ? <MLHBio />
            : <Paragraphs keyPrefix={`bio-md-${bio.id}`}>{bio.bio}</Paragraphs>
        }
      </div>
    </Column>
  </Row>
)

const MLHBio = () => (
  <p>Read my bio <a href="http://stanfordmlh.weebly.com/members.html">here</a>.</p>
)
