import React, { useState, useEffect } from 'react'
import { Slide } from 'react-slideshow-image'
import 'react-slideshow-image/dist/styles.css'
import '../stylesheets/components/slideshow.scss'
import slide1 from '../images/slideshow/slide1.jpg'
import slide2 from '../images/slideshow/slide2.jpg'

import { getRecords } from '../airtable'

// TODO: Change to a default loading image
const DEFAULT_SLIDES = [slide1, slide2]

function Slideshow () {
  const [images, setImages] = useState(DEFAULT_SLIDES)

  useEffect(() => {
    const getSlideImages = async () => {
      try {
        const records = await getRecords('Slider Images', {
          fields: ['Attachments']
        })
        const images = records.map(record => record.fields.Attachments[0].url)
        setImages(images)
      } catch (error) {
        console.log(error)
      }
    }
    getSlideImages()
  }, [])

  return (
    <div className="slide-container">
      <Slide>
        {
          images.map((image, idx) => (
            <SlideImage key={`slider-image-${idx}`} image={image} />
          ))
        }
      </Slide>
    </div>
  )
}

export default Slideshow

const SlideImage = ({ image }) => (
  <div className="each-slide">
    <div style={{ backgroundImage: `url(${image})` }}></div>
  </div>
)
