import React from 'react'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component'
import 'react-vertical-timeline-component/style.min.css'
import '../stylesheets/components/history.scss'

import { H6 } from '../components/shared/Typography'

// TODO: Insert source links
// TODO: Pull data from table instead

const EVENTS = [
  {
    date: '1969',
    title: `Asian American Student Alliance (AASA) forms/fights for
    Asian American Studies`,
    description: `SVSA’s history is closely tied to other
    Stanford API landmark moments`
  },

  {
    date: '1975',
    title: 'End of the Vietnam War',
    description: ''
  },

  {
    date: '1983',
    title: 'UVSA Socal is founded',
    description: `The Union of Vietnamese Student Associations of
    Southern California is the first large community of VSAs in
    America.`
  },

  {
    date: '1987-1988',
    title: 'SVSA is founded',
    description: ''
  },

  {
    date: '2002',
    title: 'UVSA NorCal is founded',
    description: `The Union of Vietnamese Student Associations of
    Northern California forms as a community of VSAs in the Northern
    California region consisting of 10 schools. Thus, we appoint a
    Inter-Collegiate Council Representative to liaison between
    Stanford and UVSA NorCal.`
  },

  {
    date: '2003-2004',
    title: 'Push for more Southeast Asian American Students',
    description: `SVSA, with other groups like PASU, calls on Stanford
    Admissions Office to be more mindful of the Model Minority Myth
    and to outreach to Southeast Asian Americans. During the year,
    “over 40 students staged protest outside the Dean of Admissions
    Office to demand an increase in Filipino & SEAA student outreach
    & admissions acceptances.”`
  },

  {
    date: '2003-2004',
    title: 'Beginning of PubServe’s FCAW and YACC',
    description: `The start of the annual Fall College Application
    Workshop and the Youth Academic College Conference focuses on
    introducing Southeast Asian American students to higher education.`
  },

  {
    date: '2003-2004',
    title: `Asian American Activities Center’s support for the
    Southeast Asian Community`,
    description: `The A3C becomes more mindful of providing services
    for smaller Filipino, Vietnamese, and South Asian communities. A
    Speaker Series forms specifically geared towards these students.
    The goal isn’t just to admit, but to ensure retention.`
  },

  {
    date: '2004-2005',
    title: 'UNAVSA is formed',
    description: `The Union of North American Vietnamese Student
    Association is founded as a means for Vietnamese organizations
    across North America to network, share resources, and collaborate
    to build strong sustainable communities and engage in
    philanthropic work.`
  },

  {
    date: '2007',
    title: 'Hmong Student Union and Stanford Khmer Association is founded',
    description: `As a result of student efforts to outreach to
    Southeast Asian American Communities, HSU and SKA is established
    to support these specific communities`
  },

  {
    date: '2013',
    title: 'The Professor Joel Brinkley Incident',
    description: `The late Professor Joel Brinkley wrote an article
    on January 29, 2013 in the Chicago Tribune resulting in backlash
    from the Stanford community. SVSA finds it’s article riddled with
    stereotypical assertions and cultural judgments and writes
    “Professor Brinkley has denounced the country of Vietnam and its
    people as “gruesome” and “aggressive” with a backwards diet of
    endangered animals in the midst of its rising economic status”.
    After the incident, the Community Advocacy committee was formed.`
  },

  {
    date: '2019-2020',
    title: 'The 22% Campaign',
    description: `Tibetan, Hmong, Lao, and Khmer students call for
    Stanford to disaggregate admissions data so communities can be
    recognized as underrepresented. This continues the demands more
    outreach and admissions efforts to underrepresented Asian American
    communities.`
  }
]

function History () {
  return (
    <VerticalTimeline className="history">
      <Events events={EVENTS} />
    </VerticalTimeline>
  )
}

export default History

const Events = ({ events }) => events.map((event, idx) => {
  const isLeftItem = idx % 2 === 0
  const color = isLeftItem ? 'salmon' : 'navy'
  const extraClass = isLeftItem ? 'leftItem' : 'rightItem'
  return (
    <TimelineElement
      key={`timeline-event-${idx}`}
      date={event.date}
      color={color}
    >
      <H6 className={`${extraClass} ${color}-text`}>{event.title}</H6>
      <p className={extraClass}>{event.description}</p>
    </TimelineElement>
  )
})

const TimelineElement = ({ date, color, children }) => (
  <VerticalTimelineElement
    className="timeline-event"
    contentStyle={{ background: 'transparent', boxShadow: 'none' }}
    contentArrowStyle={{ display: 'none' }}
    date={date}
    dateClassName={`bold ${color}-text date`}
    iconClassName={`timeline-icon ${color}`}
    // icon={<WorkIcon />} // TODO: add picture here
  >{children}
  </VerticalTimelineElement>
)
