const Images = {
  eventbrite: {
    src: require('./icons/eventbrite.png'),
    alt: 'eventbrite icon'
  },
  pencil: {
    src: require('./icons/pencil.png'),
    alt: 'pencil icon'
  },
  zoom: {
    src: require('./icons/zoom.svg'),
    alt: 'zoom app icon'
  },
  sticker: {
    explore: {
      src: require('./icons/sticker/explore.png'),
      alt: 'magnifying glass with icon of person in the middle of the glass part'
    },
    people: {
      src: require('./icons/sticker/people.png'),
      alt: 'a group of three user icons overlapping each other'
    },
    globe: {
      src: require('./icons/sticker/globe.png'),
      alt: 'globe icon'
    }
  },
  banner: {
    src: require('./banner.jpg'),
    alt: 'svsa banner with images of past cohorts'
  },
  dummy: {
    src: require('./dummy-event.png'),
    alt: 'blank event image'
  }
}

export default Images
