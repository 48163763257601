import React from 'react'
import BootstrapImage from 'react-bootstrap/Image'
import '../../stylesheets/components/image.scss'

function SideImage ({ img }) {
  return (
    <img
      src={img.src}
      alt={img.alt}
      className="side-img"
    />
  )
}

function ProfileImage ({ src, alt }) {
  return (
    <BootstrapImage fluid roundedCircle
      src={src}
      alt={alt}
      className="profile-img"
    />
  )
}

function EventImage({ src, alt }) {
  return (
    <img
      src={src}
      alt={alt}
      className="event-img"
    />
  )
}

function PastEventImage({ src, alt }) {
  return (
    <img
      src={src}
      alt={alt}
      className="past-event-img"
    />
  )
}

const Image = {
  Side: SideImage,
  Profile: ProfileImage,
  Event: EventImage,
  PastEvent: PastEventImage
}

export default Image
