import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons'
import '../stylesheets/components/footer.scss'

import { Button, Link } from './shared'
import { H6, SubsectionHeader } from './shared/Typography'

// TODO: update with links from database
export default function Footer () {
  return (
    <div className="footer center">
      <H6 className="center">
        &copy; Stanford Vietnamese Student Association
      </H6>
      <SubsectionHeader>Get in Touch</SubsectionHeader>
      <p>Tracy Ha, Co-Chair, <Link.Contact email="tracyha@stanford.edu" /></p>
      <p>Tracy Ly, Co-Chair, <Link.Contact email="tracyly@stanford.edu" /></p>
      <SubsectionHeader>Follow Us</SubsectionHeader>

      {/* Social Media Icons */}
      <div className="footer-icons">
        <Link.NewTab href="https://www.facebook.com/stanfordvsa/">
          <FontAwesomeIcon
            icon={faFacebookF}
            className="icon link facebook"
          />
        </Link.NewTab>
        <Link.NewTab href="https://www.instagram.com/stanford.vsa/">
          <FontAwesomeIcon
            icon={faInstagram}
            className="icon link"
          />
        </Link.NewTab>
        {/* Optional Zoom Link */}
        {/* <Link.NewTab href="https://zoom.us">
          <div className="zoom"></div>
        </Link.NewTab> */}
      </div>

      <Button.Simple href="https://mailman.stanford.edu/mailman/listinfo/stanfordvsa">Join Our Mailing List!</Button.Simple>
    </div>
  )
}
