import React from 'react'

import Util from '../../Util'
const { trimClasses } = Util

/* Headers */

export const H1 = ({ children, ...props }) => <h1 {...props}>{children}</h1>
export const H2 = ({ children, ...props }) => <h2 {...props}>{children}</h2>
export const H3 = ({ children, ...props }) => <h3 {...props}>{children}</h3>
export const H4 = ({ children, ...props }) => <h4 {...props}>{children}</h4>
export const H5 = ({ children, ...props }) => <h5 {...props}>{children}</h5>
export const H6 = ({ children, ...props }) => <h6 {...props}>{children}</h6>

export const Subheader = ({ className, children, ...props }) => (
  <h2 className={trimClasses('with-underline', className)} {...props}>
    {children}
  </h2>
)
export const SubsectionHeader = ({ className, children, ...props }) => (
  <div className={trimClasses('subsection-header', className)} {...props}>{children}</div>
)

/* Captions */

export const Caption = {
  Regular: ({ className, children, ...props }) => (
    <div className={trimClasses('caption', className)} {...props}>
      {children}
    </div>
  ),
  Semibold: ({ className, children, ...props }) => (
    <div className={trimClasses('caption extrabold', className)} {...props}>
      {children}
    </div>
  ),
  Bold: ({ className, children, ...props }) => (
    <div className={trimClasses('caption blackbold', className)} {...props}>
      {children}
    </div>
  )
}

Caption.Regular.displayName = "RegularCaption"
Caption.Semibold.displayName = "SemiboldCaption"
Caption.Bold.displayName = "BoldCaption"

/* Tags */

export const Tag = {
  Dark: ({ className, children, ...props }) => (
    <span className={trimClasses('tag dark', className)} {...props}>
      {children}
    </span>
  ),
  Light: ({ className, children, ...props }) => (
    <span className={trimClasses('tag light', className)} {...props}>
      {children}
    </span>
  )
}

Tag.Dark.displayName = "DarkTag"
Tag.Light.displayName = "LightTag"
