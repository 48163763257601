import React from 'react'
import { useState, Fragment } from 'react'
import '../../stylesheets/components/button.scss'

import Util from '../../Util'
const { trimClasses } = Util

const openInNewTab = url => {
  const win = window.open(url, '_blank')
  win.focus()
}

function Button ({ className, action, href, children }) {
  // Can run both an action an open a link
  const onClick = () => {
    if (href) openInNewTab(href)
    if (action) action()
  }
  const classes = trimClasses('button', className)
  return (
    <button className={classes} onClick={onClick}>
      {children}
    </button>
  )
}

// content1 and content2 are expected to be passed as elements
function ToggleButton({ option1, option2, content1, content2 }) {

  const [toggle, setToggle] = useState(true)
  const switchToggle = toggle => !toggle

  return (
    <Fragment>
      <div onClick={() => setToggle(switchToggle)}>
        <div className='toggle'>
          <div className={`${toggle ? 'active-option' : 'inactive-option'}`}>
            <span>{option1}</span>
          </div>
          <div className={`${toggle ? 'inactive-option' : 'active-option'}`}>
            <span>{option2}</span>
          </div>
        </div>
      </div>

      {toggle ? content1 : content2}
    </Fragment>
  )
}

const Buttons = {
  Simple: ({ action, href, children }) => (
    <Button action={action} href={href}>{children}</Button>
  ),
  Trailer: ({ children, ...props }) => (
    <Button className="bottom-attached" {...props}>{children}</Button>
  ),
  Toggle: ({ option1, option2, content1, content2 }) => (
    <ToggleButton option1={option1} option2={option2} content1={content1} content2={content2} />
  ),
  Custom: Button
}

Buttons.Simple.displayName = 'Simple';
Buttons.Trailer.displayName = 'Trailer';
Buttons.Toggle.displayName = 'Toggle';

export default Buttons
