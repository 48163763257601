import React from 'react'
import { Row } from 'react-bootstrap'
import '../stylesheets/pages/about.scss'

import Template from './Template'
import { Column, History } from '../components'
import { Image } from '../components/shared'
import { H1, Subheader } from '../components/shared/Typography'
import Images from '../images'
import Util from '../Util'

const { pageRoutes: routes, trimClasses } = Util

const POSITIONS = [
  { name: 'Co-Chair', count: 2 },
  { name: 'Financial Officer', count: 1 },
  { name: 'The Stanford Fund (TSF) Officer', count: 1 },
  { name: 'Anh Chị Em Coordinator', count: 2 },
  { name: 'Public Relations Coordinator', count: 2 },
  { name: 'Culture Coordinator', count: 2 },
  { name: 'Culture Night Director', count: 4 },
  { name: 'Community Advocacy Coordinator', count: 2 },
  { name: 'Public Service Coordinator', count: 2 },
  { name: 'Social Coordinator & InterCollegiate Council (ICC) Representative', count: 3 }
]

export default function About () {
  return (
    <Template>
      <H1>What is SVSA?</H1>
      <p>Founded in 1988, the Stanford Vietnamese Student Association
        (SVSA) has served as both a family and as a support network
        for Vietnamese students at Stanford University. SVSA members
        seek to share their rich cultural heritage with the Stanford
        campus community and foster the development of social and
        cultural ties with the greater Bay Area community.
      </p>
      <p>SVSA Core consists of officers (who are elected during Spring
        Quarter) and interns (who are chosen during the subsequent
        Fall Quarter). Members are divided into different committees,
        each with a specific focus and purpose. The list of
        positions/committees is as follows:
      </p>
      <Row className="margin-vt">
        <Column size={6} theme="yellow padding-hz">
          <PositionTitle />
          <Positions positions={POSITIONS} />
        </Column>
        <PositionItemCaption breakpoint="laptop-and-below" />
        <Column size={6}>
          <Image.Side
            img={Images.banner}
            className="side-img"
          />
        </Column>
        <PositionItemCaption breakpoint="above-laptop" />
      </Row>
      <p>
        Throughout the year, we host a variety of events, including a
        Fall College Application Workshop for high school students, an
        annual Lunar New Year festival, an annual Culture Night, many,
        many phở outings, and more! Check out a full list of our{' '}
        <a href={routes.events}>events</a> here.
      </p>
      <p>
        If you want to get more involved with SVSA, subscribe to our
        mailing list and check out our{' '}
        <a href={routes.events}>Events</a> page! :&#40;
      </p>
      <Subheader>History</Subheader>
      <History />
    </Template>
  )
}

const Positions = ({ positions }) => positions.map((position, idx) => (
  <PositionItem key={`position-item-${idx}`}
    position={position.name}
    count={position.count}
  />
))

const PositionTitle = () => <div className="positions-title">Positions</div>

const PositionItem = ({ position, count, className }) => (
  <div className={trimClasses('position-item', className)}>
    <div className="count">{count}</div>
    <div>{position}</div>
  </div>
)

const PositionItemCaption = ({ breakpoint }) => (
  <PositionItem
    position={
      '= number of spots, which can vary from year to year and does ' +
        'not include interns'
    }
    count="#"
    className={`positions-caption ${breakpoint}`}
  />
)
