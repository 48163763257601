import Airtable from 'airtable'

// These important keys are provided at:
// https://airtable.com/appDwBqT2NGtVYf1U/api/docs#curl/introduction
const API_KEY = 'keypzwq4rzY9ViHUu'
const PROD_BASE = "appDwBqT2NGtVYf1U"
const TEST_BASE = "appLp682ju695grLg"
const IS_LOCALHOST = (
  location.hostname === "localhost" || location.hostname === "127.0.0.1"
)
// Use test database while in local development; feel free to override base to
// production for testing purposes, but make sure to not commit the override
const BASE = IS_LOCALHOST ? TEST_BASE : PROD_BASE

const AirtableBase = new Airtable({ apiKey: API_KEY }).base(BASE)

const getRecords = async (table, params) => {
  const allRecords = []
  await AirtableBase(table)
    .select(params)
    .eachPage((records, fetchNextPage) => {
      allRecords.push(...records)
      fetchNextPage()
    })
  return allRecords
}

const findRecord = async (table, id) => {
  return AirtableBase(table).find(id)
}

export { getRecords, findRecord }
